import axios from 'axios';

const openaiApiKey = process.env.REACT_APP_OPENAI_API_KEY;
const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';

export const generateGroupScript = async (selectedCards) => {
  console.log('Received data for group script generation:', selectedCards);
  const characters = selectedCards.characters;
  const programs = selectedCards.programs;
  const settings = selectedCards.settings;
  const isExplicit = selectedCards.isExplicit;
  const characterAssignments = selectedCards.characterAssignments;

  let promptBase = `Create a hilarious and ${isExplicit ? 'explicit' : 'family-friendly'} 10-15 minute TV script featuring the following characters: ${characters.join(', ')}. The character assignments are: ${characterAssignments}. These characters are in the world of ${programs.join(', ')}, set/during ${settings.join(', ')}. Blend the essence of the characters and the programs, with outrageous scenarios that subvert expectations.

  Additional requirements:
  1. Ensure that all assigned characters (${characters.join(', ')}) appear in the script and have significant roles.
  2. Each character should have roughly equal participation in the script.
  3. Format the script using the following HTML tags:
     - <div class="scene-heading"> for scene headings
     - <div class="action"> for action descriptions
     - <div class="character-name"> for character names
     - <div class="parenthetical"> for parentheticals
     - <div class="dialogue"> for dialogue
     - <div class="transition"> for transitions
  4. Incorporate elements from the chosen programs and settings into the script.
  5. Only use parentheses for character names when they are assigned to a player.
  6. Make the content ${isExplicit ? 'explicit and adult-oriented' : 'family-friendly and suitable for all ages'}.
  7. Include at least four distinct scenes with different locations or time periods.
  8. Incorporate running gags, callbacks, and inside jokes throughout the script.
  9. End with a surprising twist or punchline that ties everything together.
  10. Make the dialogue snappy and include plenty of one-liners and witty exchanges.
  11. Create absurd situations that highlight the contrast between the characters and their new environment.`;

  const guidelines = isExplicit ? getRatedGuidelines() : getFamilyFriendlyGuidelines();

  try {
    const response = await axios.post(OPENAI_API_URL, {
      model: 'gpt-4',
      messages: [
        { role: 'system', content: 'You are a creative script writer specializing in comedy and unexpected plot twists. Format the script with HTML tags as specified in the prompt.' },
        { role: 'user', content: promptBase + guidelines }
      ],
      max_tokens: 2000,
      temperature: 0.8,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${openaiApiKey}`,
      },
    });

    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error generating group script:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const continueScript = async (currentScript, isExplicit) => {
  const guidelines = isExplicit ? getRatedGuidelines() : getFamilyFriendlyGuidelines();
  const prompt = `Continue the following script for 5-7 more minutes, maintaining the same tone, style, and character assignments. Introduce a new twist or unexpected event to keep the story engaging. Use the same HTML formatting as in the original script:\n\n${currentScript}\n\n${guidelines}`;

  try {
    const response = await axios.post(
      OPENAI_API_URL,
      {
        model: 'gpt-4',
        messages: [
          { role: 'system', content: 'You are a creative script writer specializing in comedy and unexpected plot twists. Continue the script using the same HTML formatting as the original.' },
          { role: 'user', content: prompt },
        ],
        max_tokens: 1500,
        temperature: 0.8,
      },
      {
        headers: {
          Authorization: `Bearer ${openaiApiKey}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data.choices[0].message.content.trim();
  } catch (error) {
    console.error('Error continuing script:', error.response ? error.response.data : error.message);
    throw error;
  }
};

const getFamilyFriendlyGuidelines = () => `
Key guidelines:
1. Keep the content suitable for all ages, avoiding explicit language or themes.
2. Create absurd situations that highlight the contrast between the characters and their new environment.
3. Include witty, quotable dialogue that feels fresh and surprising.
4. Use clever wordplay and situational humor appropriate for family viewing.
5. Speak in the characters' true voices - use their vernacular, expressions. Not too much but enough.
6. Use active voice and structure the text like a TV script.
7. Incorporate physical comedy and visual gags that can be easily imagined.
8. Create humorous misunderstandings or mix-ups between characters from different universes.

Style notes:
- Think 'alternate universe' where these characters exist in unexpected but family-friendly situations.
- Use irony and meta-humor to comment on the absurdity of the mashup.
- Feel free to break the fourth wall or include surreal elements, keeping it appropriate for all ages.

Remember: The goal is to create a script that's genuinely funny and unexpected while remaining family-friendly.`;

const getRatedGuidelines = () => `
Key guidelines:
1. Create content suitable for mature audiences, allowing for more explicit themes and language.
2. Develop absurd and potentially risqué situations that highlight the contrast between the characters and their new environment.
3. Include witty, quotable dialogue that feels fresh, surprising, and edgy.
4. Incorporate subtle nods to real-world controversies or quirks associated with the characters, twisting them in clever, unexpected ways.
5. Aim for humor that's clever and edgy, pushing boundaries without being gratuitous.
6. Speak in the characters' true voices - use their vernacular, expressions. Not too much but enough.
7. Use active voice and structure the text like a TV script.
8. Include callbacks to famous scenes or quotes from the original programs, but with an adult twist.

Style notes:
- Think 'alternate universe' where these characters exist in completely unexpected and mature situations.
- Use irony and meta-humor to comment on the absurdity of the mashup.
- Don't be afraid to break the fourth wall or include surreal elements.
- Play with the contrast between wholesome characters and adult situations for comedic effect.

Remember: The goal is to create a script that's genuinely funny, unexpected, and suited for mature audiences.`;