import React, { useState, useEffect, useCallback, useReducer, useMemo } from 'react';
import {
    Typography,
    Button,
    CircularProgress,
    Box,
    Grid,
    Card,
    CardMedia,
    CardContent,
    Snackbar,
    Alert,
    Container,
    Paper,
    Fade,
    Zoom,
    LinearProgress
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { generateGroupScript, continueScript } from '../groupplayapi';
import styles from './GroupPlayScreen.module.css';
import { ShuffleRounded, MovieCreationRounded, CasinoRounded } from '@mui/icons-material';

// Initial state and reducer function (unchanged)
const initialSelectedCards = {
    characters: [],
    programs: [],
    settings: [],
};

const selectedCardsReducer = (state, action) => {
    switch (action.type) {
        case 'SELECT_CARD':
            return {
                ...state,
                [action.category]: [action.card],
            };
        case 'RESET_SELECTION':
            return initialSelectedCards;
        default:
            return state;
    }
};

// Utility functions
const shuffleArray = (array) => {
    return array
        .map((a) => ({ sort: Math.random(), value: a }))
        .sort((a, b) => a.sort - b.sort)
        .map((a) => a.value);
};

const programCharacterMap = {
    '30 Rock': ["Liz Lemon", "Jack Donaghy", "Tracy Jordan", "Jenna Maroney", "Kenneth Parcell"],
    'Jersey Shore': ["Mike 'The Situation' Sorrentino", "Nicole 'Snooki' Polizzi", "Jenni 'JWoww' Farley", "Paul 'Pauly D' DelVecchio", "Vinny Guadagnino"],
    'The Office': ["Michael Scott", "Dwight Schrute", "Jim Halpert", "Pam Beesly", "Ryan Howard", "Andy Bernard", "Angela Martin"],
    'The Sopranos': ["Tony Soprano", "Carmela Soprano", "Christopher Moltisanti", "Paulie Gualtieri", "Silvio Dante", "Meadow Soprano", "A.J. Soprano"],
    'Titanic': ["Jack Dawson", "Rose DeWitt Bukater", "Cal Hockley", "Molly Brown", "Captain Smith", "Thomas Andrews"],
    'Willy Wonka and the Chocolate Factory': ["Willy Wonka", "Charlie Bucket", "Veruca Salt", "Violet Beauregarde", "Augustus Gloop", "Mike Teavee", "Grandpa Joe"],
};

const GroupPlayScreen = () => {
    const { state } = useLocation();
    const { players, scriptRating } = state || {};
    const navigate = useNavigate();

    const playerNames = useMemo(() => players ? Object.values(players) : [], [players]);

    const [randomCards, setRandomCards] = useState({
        characters: [],
        programs: [],
        settings: [],
    });

    const [selectedCards, dispatchSelectedCards] = useReducer(selectedCardsReducer, initialSelectedCards);
    const [script, setScript] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [assignedCharacters, setAssignedCharacters] = useState({});
    const [isGenerating, setIsGenerating] = useState(false);
    const [isContinuing, setIsContinuing] = useState(false);
    const [scriptGenerationProgress, setScriptGenerationProgress] = useState(0);

    const fetchCards = useCallback(async () => {
        try {
            const collections = ['characters', 'programs', 'settings'];
            const allCards = {};
            for (const coll of collections) {
                const querySnapshot = await getDocs(collection(db, coll));
                allCards[coll] = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            }
            return allCards;
        } catch (err) {
            handleError(err);
            return {
                characters: [],
                programs: [],
                settings: [],
            };
        }
    }, []);

    const getCharactersFromSelectedPrograms = useCallback((selectedPrograms) => {
        let characters = [];
        selectedPrograms.forEach(program => {
            if (programCharacterMap[program]) {
                characters = characters.concat(programCharacterMap[program]);
            }
        });
        return shuffleArray(characters);
    }, []);

    const shuffleCards = useCallback((allCards) => {
        const shuffled = {};
        Object.keys(allCards).forEach(category => {
            if (allCards[category].length >= 3) {
                shuffled[category] = shuffleArray(allCards[category]).slice(0, 3);
            } else {
                handleError(new Error(`Not enough ${category} cards available.`));
                shuffled[category] = [];
            }
        });
        setRandomCards(shuffled);
        return shuffled;
    }, []);

    const assignCharactersToPlayers = useCallback((playerNames, selectedCharacters, selectedPrograms) => {
        const assignments = {};
        const usedCharacters = new Set();

        // Assign the first player to the selected character card
        if (selectedCharacters.length > 0 && playerNames.length > 0) {
            assignments[playerNames[0]] = selectedCharacters[0];
            usedCharacters.add(selectedCharacters[0]);
        }

        // Get characters from the selected program
        const programCharacters = getCharactersFromSelectedPrograms(selectedPrograms)
            .filter(character => !usedCharacters.has(character));

        // Assign remaining players to random characters from the program
        playerNames.slice(1).forEach((player, index) => {
            const character = programCharacters[index % programCharacters.length];
            assignments[player] = character;
            usedCharacters.add(character);
        });

        setAssignedCharacters(assignments);
        return assignments;
    }, [getCharactersFromSelectedPrograms]);

    const toggleSelect = useCallback((card, category) => {
        dispatchSelectedCards({ type: 'SELECT_CARD', category, card });
    }, []);

    const handleGenerateScript = useCallback(async () => {
        setIsGenerating(true);
        setError(null);
        setScriptGenerationProgress(0);
        try {
            const characterNames = selectedCards.characters.map(card => card.name);
            const programNames = selectedCards.programs.map(card => card.name);
            const settingNames = selectedCards.settings.map(card => card.name);

            const assignments = assignCharactersToPlayers(playerNames, characterNames, programNames);
            const characterAssignments = Object.entries(assignments)
                .map(([player, character]) => `${character} (${player})`)
                .join(', ');

            let scriptText = await generateGroupScript({
                characters: Object.values(assignments),
                programs: programNames,
                settings: settingNames,
                isExplicit: scriptRating === 'r-rated',
                characterAssignments: characterAssignments,
                onProgress: (progress) => setScriptGenerationProgress(progress),
            });

            if (typeof scriptText !== 'string') {
                throw new Error('Generated script is not a string');
            }

            setScript(scriptText);
        } catch (err) {
            handleError(err);
        }
        setIsGenerating(false);
        setScriptGenerationProgress(0);
    }, [selectedCards, assignCharactersToPlayers, playerNames, scriptRating]);

    const handleContinueScript = useCallback(async () => {
        setIsContinuing(true);
        setError(null);
        try {
            const continuedScript = await continueScript(script, scriptRating === 'r-rated');
            setScript(prevScript => `${prevScript}\n\n${continuedScript}`);
        } catch (err) {
            handleError(err);
        }
        setIsContinuing(false);
    }, [script, scriptRating]);

    useEffect(() => {
        const initializeGame = async () => {
            const allCards = await fetchCards();
            if (allCards.characters.length || allCards.programs.length || allCards.settings.length) {
                shuffleCards(allCards);
            }
            setLoading(false);
        };

        initializeGame();
    }, [fetchCards, shuffleCards]);

    const formatScript = useCallback((scriptText) => {
        const lines = scriptText.split('\n');
        return lines.map((line, index) => {
            line = line.trim();
            
            // Scene Headings: e.g., SCENE 1: LOCATION - TIME
            if (/^SCENE\s+\d+:/i.test(line)) {
                return (
                    <Typography key={index} variant="h6" className={styles.sceneHeading}>
                        {line}
                    </Typography>
                );
            }
            // Transitions: e.g., CUT TO:, FADE OUT., etc.
            else if (/^(CUT TO:|CUT BACK TO:|FADE OUT\.|FADE IN\.)$/i.test(line)) {
                return (
                    <Typography key={index} variant="h6" className={styles.transition}>
                        {line}
                    </Typography>
                );
            }
            // Scene Headings starting with INT./EXT. for backward compatibility
            else if (line.startsWith('INT.') || line.startsWith('EXT.')) {
                return (
                    <Typography key={index} variant="h6" className={styles.sceneHeading}>
                        {line}
                    </Typography>
                );
            }
            // Character Names: e.g., SHAGGY:
            else if (/^[A-Z\s]+:$/i.test(line)) {
                return (
                    <Typography key={index} variant="subtitle1" className={styles.characterName}>
                        {line}
                    </Typography>
                );
            }
            // Parentheticals: e.g., (tripping)
            else if (line.startsWith('(') && line.endsWith(')')) {
                return (
                    <Typography key={index} variant="body2" className={styles.parenthetical}>
                        {line}
                    </Typography>
                );
            }
            // Action Lines
            else if (line.toUpperCase() === line) { // Assuming action lines are in uppercase
                return (
                    <Typography key={index} variant="body1" className={styles.action}>
                        {line}
                    </Typography>
                );
            }
            // Dialogue Lines
            else if (line) {
                return (
                    <Typography key={index} variant="body1" className={styles.dialogue}>
                        {line}
                    </Typography>
                );
            }
            // Empty Line
            else {
                return <br key={index} />;
            }
        });
    }, []);

    const handleError = (error) => {
        console.error('Error:', error);
        let message = 'An unexpected error occurred. Please try again.';
        if (error.message.includes('network')) {
            message = 'Network error. Please check your internet connection.';
        } else if (error.message.includes('timeout')) {
            message = 'Request timed out. Please try again.';
        } else if (error.message.includes('cards')) {
            message = error.message;
        }
        setError(message);
    };

    const handleShuffleCards = useCallback(() => {
        shuffleCards(randomCards);
        dispatchSelectedCards({ type: 'RESET_SELECTION' });
    }, [randomCards, shuffleCards]);

    return (
        <Container maxWidth="lg" className={styles.groupPlayContainer}>
            <Paper elevation={3} className={styles.contentWrapper}>
                <Typography variant="h4" gutterBottom align="center">
                    Group Play
                </Typography>

                <Typography variant="h6" gutterBottom align="center">
                    Players: {playerNames.join(', ')}
                </Typography>

                {loading ? (
                    <Box className={styles.loadingOverlay}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Fade in={!loading}>
                        <Box>
                            <Typography variant="h5" gutterBottom align="center" className={styles.playerPrompt}>
                                Select one card from each category
                            </Typography>

                            <Grid container spacing={3} className={styles.categorySections}>
                                {['characters', 'programs', 'settings'].map((category) => (
                                    <Grid item xs={12} md={4} key={category}>
                                        <Typography variant="h6" gutterBottom className={styles.categoryTitle}>
                                            {category.charAt(0).toUpperCase() + category.slice(1)}
                                        </Typography>
                                        <Grid container spacing={2} className={styles.cardsGrid}>
                                            {randomCards[category].map((card) => (
                                                <Grid item xs={12} sm={6} md={4} key={card.id}>
                                                    <Zoom in={true} style={{ transitionDelay: '250ms' }}>
                                                        <Card
                                                            className={`${styles.card} ${selectedCards[category].some(selectedCard => selectedCard.id === card.id) ? styles.selected : ''}`}
                                                            onClick={() => toggleSelect(card, category)}
                                                            raised={selectedCards[category].some(selectedCard => selectedCard.id === card.id)}
                                                        >
                                                            <CardMedia
                                                                component="img"
                                                                className={styles.cardMedia}
                                                                image={card.image_url}
                                                                alt={card.name}
                                                            />
                                                            <CardContent className={styles.cardContent}>
                                                                <Typography variant="body2" component="p">
                                                                    {card.name}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Zoom>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>

                            <Box className={styles.actionButtons}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleGenerateScript}
                                    disabled={
                                        isGenerating ||
                                        !selectedCards.characters.length ||
                                        !selectedCards.programs.length ||
                                        !selectedCards.settings.length
                                    }
                                    className={styles.generateScript}
                                    startIcon={<MovieCreationRounded />}
                                >
                                    {isGenerating ? 'Generating...' : 'Generate Script'}
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleContinueScript}
                                    disabled={!script || isContinuing}
                                    className={styles.continueScript}
                                    startIcon={<CasinoRounded />}
                                >
                                    {isContinuing ? 'Continuing...' : 'Continue Script'}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="info"
                                    onClick={handleShuffleCards}
                                    className={styles.shuffleCards}
                                    startIcon={<ShuffleRounded />}
                                >
                                    Shuffle Cards
                                </Button>
                                <Button
                                    variant="text"
                                    onClick={() => navigate('/')}
                                    className={styles.finish}
                                >
                                    Finish
                                </Button>
                            </Box>

                            {isGenerating && (
                                <Box sx={{ width: '100%', mt: 2 }}>
                                    <LinearProgress variant="determinate" value={scriptGenerationProgress} />
                                    <Typography variant="body2" color="text.secondary" align="center">
                                        Generating script... {Math.round(scriptGenerationProgress)}%
                                    </Typography>
                                </Box>
                            )}

                            {script && (
                                <Paper elevation={2} className={styles.scriptContainer}>
                                    <Typography variant="h5" gutterBottom>
                                        Generated Script:
                                    </Typography>
                                    <Box className={styles.scriptText}>
                                        {formatScript(script)}
                                    </Box>
                                </Paper>
                            )}

{Object.keys(assignedCharacters).length > 0 && (
                                <Paper elevation={2} className={styles.assignedCharactersSection}>
                                    <Typography variant="h6" gutterBottom>
                                        Assigned Characters
                                    </Typography>
                                    <Grid container spacing={2}>
                                        {Object.entries(assignedCharacters).map(([player, character]) => (
                                            <Grid item xs={12} sm={6} md={4} key={player}>
                                                <Card className={styles.assignmentCard}>
                                                    <CardContent>
                                                        <Typography variant="subtitle1" component="div">
                                                            {player}
                                                        </Typography>
                                                        <Typography variant="body1" component="div">
                                                            {character}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Paper>
                            )}

                            <Snackbar
                                open={!!error}
                                autoHideDuration={6000}
                                onClose={() => setError(null)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            >
                                <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
                                    {error}
                                </Alert>
                            </Snackbar>
                        </Box>
                    </Fade>
                )}
            </Paper>
        </Container>
    );
};

export default GroupPlayScreen;