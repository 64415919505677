import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Typography, Button, CircularProgress, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../firebase';
import { generateScript, continueScript } from '../api';
import { collection, getDocs } from 'firebase/firestore';
import styles from './QuickGameScreen.module.css';
import scriptStyles from '../styles/scriptFormat.module.css';
import layoutStyles from '../styles/layout.module.css';
import cardStyles from '../styles/card.module.css';
import buttonStyles from '../styles/actionButtons.module.css';

const QuickGameScreen = () => {
  const [cards, setCards] = useState({ characters: [], programs: [], settings: [] });
  const [selectedCards, setSelectedCards] = useState({ characters: [], programs: [], settings: [] });
  const [script, setScript] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const isExplicit = location.state?.isExplicit ?? false;

  const fetchCards = useCallback(async () => {
    try {
      const collections = ['characters', 'programs', 'settings'];
      const allCards = {};
      for (const coll of collections) {
        const querySnapshot = await getDocs(collection(db, coll));
        allCards[coll] = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      }
      console.log('Fetched cards:', allCards);
      setCards(allCards);
      return allCards;
    } catch (error) {
      console.error('Error fetching cards:', error);
      return {};
    }
  }, []);

  const getRandomCard = useMemo(() => (cardArray) => {
    return cardArray[Math.floor(Math.random() * cardArray.length)];
  }, []);

  const shuffleCards = useCallback((allCards) => {
    const shuffled = { characters: [], programs: [], settings: [] };
    for (const category in allCards) {
      if (allCards[category] && allCards[category].length > 0) {
        const randomCard = getRandomCard(allCards[category]);
        shuffled[category] = [randomCard];
      } else {
        console.error(`No cards found for category: ${category}`);
      }
    }
    console.log('Shuffled cards:', shuffled);
    return shuffled;
  }, [getRandomCard]);

  const handleGenerateScript = useCallback(async (cardsToUse) => {
    setLoading(true);
    try {
      if (!cardsToUse.characters.length || !cardsToUse.programs.length || !cardsToUse.settings.length) {
        throw new Error('No cards selected');
      }

      let scriptText = await generateScript({
        character: cardsToUse.characters[0].name,
        program: cardsToUse.programs[0].name,
        setting: cardsToUse.settings[0].name,
        isExplicit: isExplicit
      });

      scriptText = scriptText.replace(/^```(?:html|jsx)?\n?/i, '').trim();
      console.log('Generated script:', scriptText);

      if (typeof scriptText !== 'string') {
        throw new Error('Generated script is not a string');
      }

      setScript(scriptText);
    } catch (err) {
      console.error('Script generation error:', err);
      setScript(err.message.includes('429') 
        ? 'Too many requests. Please wait a moment and try again.'
        : err.message === 'No cards selected'
        ? 'Please wait while cards are being loaded...'
        : 'Failed to generate script. Please try again. Error: ' + err.message);
    }
    setLoading(false);
  }, [isExplicit]);

  const handleShuffle = useCallback(async () => {
    setLoading(true);
    const shuffledCards = shuffleCards(cards);
    setSelectedCards(shuffledCards);
    await handleGenerateScript(shuffledCards);
    setLoading(false);
  }, [cards, shuffleCards, handleGenerateScript]);

  const handleContinueScript = useCallback(async () => {
    setLoading(true);
    try {
      let continuedScript = await continueScript(script);
      continuedScript = continuedScript.replace(/^```(?:html|jsx)?\n?/i, '').trim();
      setScript(prevScript => `${prevScript}\n\n${continuedScript}`);
    } catch (err) {
      console.error('Script continuation error:', err.message);
      // Consider adding user feedback for the error here
    }
    setLoading(false);
  }, [script]);

  useEffect(() => {
    const initializeGame = async () => {
      try {
        const allCards = await fetchCards();
        if (Object.keys(allCards).length === 0) {
          throw new Error('No cards fetched from the database');
        }
        const shuffledCards = shuffleCards(allCards);
        if (Object.keys(shuffledCards).length === 0) {
          throw new Error('Failed to shuffle cards');
        }
        setSelectedCards(shuffledCards);
        await handleGenerateScript(shuffledCards);
      } catch (error) {
        console.error('Error initializing game:', error);
        setScript('Failed to initialize game. Please try again. Error: ' + error.message);
        setLoading(false);
      }
    };

    initializeGame();
  }, [fetchCards, shuffleCards, handleGenerateScript]);

  const renderCardSection = useCallback(([category, cards]) => (
    <div key={category} className={styles.categorySection}>
      <Typography variant="h6" className={styles.categoryTitle}>{category}</Typography>
      <div className={styles.cardsGrid}>
        {Array.isArray(cards) && cards.length > 0 ? (
          cards.map((card) => (
            <div key={card.id} className={`${cardStyles.card} ${styles.card}`}>
              <div className={`${cardStyles.cardMedia} ${styles.cardMedia}`} style={{ backgroundImage: `url(${card.image_url})` }}></div>
              <div className={`${cardStyles.cardContent} ${styles.cardTitle}`}>{card.name}</div>
            </div>
          ))
        ) : (
          <Typography>No card selected</Typography>
        )}
      </div>
    </div>
  ), []);

  return (
    <div className={layoutStyles.container}>
      <div className={layoutStyles.contentWrapper}>
        {loading ? (
          <div className={layoutStyles.loadingOverlay}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className={styles.cardsContainer}>
              {Object.entries(selectedCards).map(renderCardSection)}
            </div>
            <Typography variant="subtitle1">
              Mode: {isExplicit ? 'R-Rated' : 'Family Friendly'}
            </Typography>
            {script && (
              <Box className={scriptStyles.scriptContainer}>
                <div 
                  className={scriptStyles.scriptText}
                  dangerouslySetInnerHTML={{ __html: script }}
                />
              </Box>
            )}
            <div className={buttonStyles.actionButtons}>
              <Button 
                variant="contained" 
                onClick={handleShuffle} 
                className={`${buttonStyles.actionButton} ${styles.shuffleButton}`}
              >
                Shuffle
              </Button>
              <Button 
                variant="contained" 
                onClick={handleContinueScript} 
                className={`${buttonStyles.actionButton} ${styles.continueScriptButton}`} 
                disabled={loading || !script}
              >
                Continue Script
              </Button>
              <Button 
                variant="contained" 
                onClick={() => navigate('/')} 
                className={`${buttonStyles.actionButton} ${styles.finishButton}`}
              >
                Finish
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default QuickGameScreen;