import axios from 'axios';

const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';
const openaiApiKey = process.env.REACT_APP_OPENAI_API_KEY;

const axiosInstance = axios.create({
  baseURL: OPENAI_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${openaiApiKey}`,
  },
});

const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const getGuidelines = (isExplicit) => isExplicit ? getRatedGuidelines() : getFamilyFriendlyGuidelines();

const createPrompt = (selectedCards, isExplicit) => {
  const { character = 'Undefined Character', program = 'Undefined Program', setting = 'Undefined Setting' } = selectedCards;
  
  return `Create a ${isExplicit ? 'hilarious and explicit' : 'hilarious, unexpected'} 5 minute TV script featuring ${character} thrown into the world of ${program}. The scene is set with ${character} ${setting}. 

  Key requirements:
  1. Main character: ${character}
     - Maintain their core personality traits, quirks, and mannerisms
     - Adapt their typical behavior to fit the new context

  2. World and style: ${program}
     - Incorporate iconic elements, locations, and themes from ${program}
     - Mimic the pacing, tone, and narrative style of ${program}

  3. Situation: ${setting}
     - Use ${setting} as the primary backdrop or catalyst for the story
     - Explore how ${character} interacts with and reacts to this setting

  4. Blend and subvert expectations:
     - Create outrageous scenarios by merging ${character}'s traits with ${program}'s universe
     - Develop unexpected plot twists that challenge both ${character} and the world of ${program}
     - Use irony and contrast for comedic effect

  5. Character integrity:
     - Ensure ${character}'s actions and dialogue remain true to their established persona
     - Allow for character growth or revelations that make sense within this new context

  6. TV script structure:
     - Use a standard 5-act structure: teaser, three main acts, and a tag/epilogue
     - Include a mix of dialogue, action, and scene descriptions
     - Aim for a balance of character interactions and plot advancement

  7. Pacing and length:
     - Script should feel like a complete 5-minute episode
     - Maintain a brisk pace with quick scenes and snappy dialogue
     - Include at least one major comedic set piece or memorable moment

  8. HTML formatting:
     <scene-heading>INT./EXT. LOCATION - TIME OF DAY</scene-heading>
     <action>Detailed description of the scene, character actions, or environment</action>
     <character>${character.toUpperCase()}</character>
     <parenthetical>(emotion or action)</parenthetical>
     <dialogue>Character's spoken lines</dialogue>
     <transition>CUT TO: or FADE TO: etc.</transition>

  9. Comedy style:
     ${isExplicit ? 'Push boundaries with mature themes and language, but avoid gratuitous content' : 'Keep humor family-friendly and clever, focusing on situational comedy and wordplay'}

  10. Easter eggs and references:
      - Include subtle nods to both ${character}'s original context and ${program}'s universe
      - Reward fans of both properties with inside jokes or callbacks`;

};

const makeOpenAIRequest = async (messages, retries = 3) => {
  try {
    const response = await axiosInstance.post('', {
      model: 'gpt-4',
      messages,
      max_tokens: 1000,
      temperature: 0.8,
    });
    return response.data.choices[0].message.content;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 429 && retries > 0) {
      const delay = Math.pow(2, 4 - retries) * 1000;
      console.log(`Rate limited. Retrying in ${delay / 1000} seconds...`);
      await wait(delay);
      return makeOpenAIRequest(messages, retries - 1);
    }
    console.error('Error making OpenAI request:', error.response?.data || error.message);
    throw error;
  }
};

export const generateScript = async (selectedCards) => {
  console.log('Received data for script generation:', selectedCards);
  const { isExplicit = false } = selectedCards;
  const prompt = createPrompt(selectedCards, isExplicit) + getGuidelines(isExplicit);

  const messages = [
    { role: 'system', content: 'You are a creative script writer. Format the script with HTML tags as specified in the prompt.' },
    { role: 'user', content: prompt }
  ];

  return makeOpenAIRequest(messages);
};

export const continueScript = async (currentScript, isExplicit) => {
  const guidelines = getGuidelines(isExplicit);
  const prompt = `Continue the following script for 3-5 more minutes, maintaining the same tone, style, and characters. Use the same HTML formatting as in the original script:\n\n${currentScript}\n\n${guidelines}`;

  const messages = [
    { role: 'system', content: 'You are a creative script writer. Continue the script using the same HTML formatting as the original.' },
    { role: 'user', content: prompt },
  ];

  return makeOpenAIRequest(messages);
};

const getFamilyFriendlyGuidelines = () => `
Key guidelines:
1. Content appropriateness:
   - Keep all content suitable for all ages, avoiding explicit language or themes
   - Use euphemisms or creative alternatives for potentially sensitive topics

2. Situational comedy:
   - Create absurd, fish-out-of-water scenarios highlighting the character-environment contrast
   - Develop comical misunderstandings based on the character's background in this new world

3. Dialogue and wordplay:
   - Craft witty, quotable lines that are fresh, surprising, and memorable
   - Utilize puns, double entendres (kid-friendly), and clever wordplay
   - Incorporate catchphrases or signature expressions from the original character/show

4. Character authenticity:
   - Maintain the character's true voice, using their typical vernacular and expressions
   - Balance character authenticity with adaptability to the new environment

5. Narrative structure:
   - Use active voice and follow standard TV script formatting
   - Employ classic sitcom story structures (e.g., misunderstanding snowballs, character learns a lesson)

6. Family-friendly humor techniques:
   - Focus on physical comedy, slapstick, and visual gags
   - Utilize dramatic irony where the audience knows more than the characters
   - Incorporate running gags that build throughout the episode

Style notes:
- Envision an 'alternate universe' where this character exists in an unexpected but family-friendly situation
- Use irony and meta-humor to highlight the absurdity of the crossover concept
- Employ fourth-wall breaks or surreal elements that remain appropriate for all ages
- Draw inspiration from successful family-friendly shows that appeal to both children and adults

Remember: The goal is to create a script that's genuinely funny, unexpected, and cleverly written while remaining suitable for family viewing.`;

const getRatedGuidelines = () => `
Key guidelines:
1. Mature content:
   - Create content suitable for adult audiences, allowing for explicit themes and language
   - Balance edgy humor with storytelling; avoid gratuitous content for shock value alone

2. Absurd and risqué situations:
   - Develop outrageous scenarios that amplify the character-environment contrast
   - Push boundaries with unexpected twists on the character's typical situations

3. Dialogue and wit:
   - Craft sharp, quotable dialogue that's fresh, surprising, and edgy
   - Blend highbrow and lowbrow humor for a diverse comedic palette
   - Incorporate innuendo, satirical elements, and clever references

4. Character exploration:
   - Maintain the character's essence while exploring new facets in this mature context
   - Play with the tension between the character's established persona and adult situations

5. Topical and meta-humor:
   - Include subtle nods to real-world controversies or quirks associated with the character
   - Twist familiar elements from the character's world in clever, unexpected ways
   - Employ satire to comment on broader societal issues or pop culture trends

6. Narrative structure:
   - Use active voice and follow professional TV script formatting
   - Experiment with non-linear storytelling or unique narrative devices

7. Mature humor techniques:
   - Utilize dark comedy, gallows humor, or absurdist elements
   - Employ juxtaposition between the character's typical world and adult themes
   - Incorporate running gags that escalate in audacity throughout the episode

Style notes:
- Envision an 'alternate universe' where this character exists in a completely unexpected and mature situation
- Leverage irony and meta-humor to emphasize the absurdity of the mashup
- Freely break the fourth wall or include surreal elements to enhance the comedy
- Draw inspiration from successful adult animated series or late-night comedy shows

Remember: The goal is to create a script that's genuinely funny, unexpected, and cleverly written while catering to a mature audience. Push boundaries thoughtfully, prioritizing smart humor over mere shock value.`;