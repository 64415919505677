// /Users/jackson/skitz/src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomeScreen from './pages/HomeScreen';
import QuickGameScreen from './pages/QuickGameScreen';
import HeadToHeadSetup from './pages/HeadToHeadSetup';
import HeadToHeadScreen from './pages/HeadToHeadScreen';
import GroupPlaySetup from './pages/GroupPlaySetup';
import About from './pages/About';
import Contact from './pages/Contact';
import AuthPage from './pages/AuthPage';
import PrivateRoute from './components/PrivateRoute';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import GroupPlayScreen from './pages/GroupPlayScreen'; // Add this import

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        {user && <Navbar />}
        <Routes>
          <Route path="/auth" element={user ? <Navigate to="/" /> : <AuthPage />} />
          <Route path="/" element={<PrivateRoute><HomeScreen /></PrivateRoute>} />
          <Route path="/quickgame" element={<PrivateRoute><QuickGameScreen /></PrivateRoute>} />
          <Route path="/headtohead-setup" element={<PrivateRoute><HeadToHeadSetup /></PrivateRoute>} />
          <Route path="/headtohead-game" element={<PrivateRoute><HeadToHeadScreen /></PrivateRoute>} />
          <Route path="/groupplay-setup" element={<PrivateRoute><GroupPlaySetup /></PrivateRoute>} />
          <Route path="/about" element={<PrivateRoute><About /></PrivateRoute>} />
          <Route path="/contact" element={<PrivateRoute><Contact /></PrivateRoute>} />
          <Route path="/group-play-screen" element={<PrivateRoute><GroupPlayScreen /></PrivateRoute>} /> {/* Add this line */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
