import React, { useState } from 'react';
import styles from './Contact.module.css';
import Confetti from 'react-confetti';

function Contact() {
  const [stage, setStage] = useState(0);
  const [choices, setChoices] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [completed, setCompleted] = useState(false);

  const scenarios = [
    {
      text: "Hello! Want to get in touch with Jackson, the code wizard?",
      options: ["Yes, let's do this!", "Not today, maybe later"],
      action: (choice) => choice === 1 && window.history.back(),
    },
    {
      text: "Great! How do you prefer to connect?",
      options: ["Email", "Phone", "Carrier Pigeon (Yep, we still use it!)"],
      action: null,
    },
    {
      text: "Before we proceed, a quick fun fact question: What’s Jackson’s favorite coding language?",
      options: ["JavaScript", "Python", "C++", "Mystery... 🤔"],
      action: (choice) => {
        if (choice === 0 || choice === 1) {
          setShowConfetti(true); // Reward correct choices
        }
      },
    },
    {
      text: "Nice choice! Almost done. What's the next big step you'd like to take?",
      options: ["Discuss a project", "Schedule a call", "Share some memes"],
      action: null,
    },
    {
      text: "Congrats! Here’s Jackson's contact info, but with a twist:",
      options: ["Reveal the info 🎉", "Start Over"],
      action: (choice) => choice === 1 && resetJourney(),
      info: (
        <>
          <p>👨‍💻 Jackson, Code Enthusiast</p>
          <p>📞 Phone: 814-321-6569</p>
          <p>📧 Email: jackson@bertlegs.com</p>
          <p>🚀 Fun fact: Loves tabs over spaces!</p>
        </>
      ),
    },
  ];

  const handleChoice = (choice) => {
    if (stage === scenarios.length - 1 && choice === 0) {
      setCompleted(true);
    } else {
      if (scenarios[stage].action) {
        scenarios[stage].action(choice);
      }
      setChoices([...choices, scenarios[stage].options[choice]]);
      setStage(stage + 1);
    }
  };

  const resetJourney = () => {
    setStage(0);
    setChoices([]);
    setShowConfetti(false);
    setCompleted(false);
  };

  return (
    <div className={styles.contactContainer}>
      <h2>Connect with Jackson</h2>
      {showConfetti && <Confetti />}
      {stage < scenarios.length - 1 && (
        <>
          <p>{scenarios[stage].text}</p>
          <div className={styles.options}>
            {scenarios[stage].options.map((option, index) => (
              <button key={index} onClick={() => handleChoice(index)}>
                {option}
              </button>
            ))}
          </div>
        </>
      )}

      {stage === scenarios.length - 1 && (
        <div className={styles.contactInfo}>
          <p>Your journey: {choices.join(' → ')}</p>
          {completed ? scenarios[stage].info : (
            <div className={styles.options}>
              {scenarios[stage].options.map((option, index) => (
                <button key={index} onClick={() => handleChoice(index)}>
                  {option}
                </button>
              ))}
            </div>
          )}
          {completed && (
            <button className={styles.resetButton} onClick={resetJourney}>
              Start Over
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default Contact;
