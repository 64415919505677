// /Users/jackson/skitz/src/pages/AuthPage.js

import React, { useState, useEffect } from 'react';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';
import { auth } from '../firebase';
import {
  TextField,
  Button,
  Typography,
  Box,
  Snackbar,
  IconButton,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import { Alert } from '@mui/material';
import {
  Visibility,
  VisibilityOff,
  Google as GoogleIcon,
  Email as EmailIcon,
} from '@mui/icons-material';
import styles from './AuthPage.module.css';
import { useNavigate } from 'react-router-dom';

const AuthPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // For password visibility toggle
  const [error, setError] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [titleIndex, setTitleIndex] = useState(0);
  const titleWords = ['Plot', 'Twistz'];

  useEffect(() => {
    const interval = setInterval(() => {
      setTitleIndex((prevIndex) => (prevIndex + 1) % titleWords.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [titleWords.length]);

  // Function to handle authentication with email and password
  const handleEmailAuth = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    try {
      if (isSignUp) {
        await createUserWithEmailAndPassword(auth, email, password);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      navigate('/');
    } catch (err) {
      handleAuthError(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle Google Sign-In
  const handleGoogleSignIn = async () => {
    setError('');
    setIsLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      navigate('/');
    } catch (err) {
      handleAuthError(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle authentication errors with user-friendly messages
  const handleAuthError = (err) => {
    let message = '';
    switch (err.code) {
      case 'auth/email-already-in-use':
        message = 'This email is already in use.';
        break;
      case 'auth/invalid-email':
        message = 'Please enter a valid email address.';
        break;
      case 'auth/user-not-found':
        message = 'No account found with this email.';
        break;
      case 'auth/wrong-password':
        message = 'Incorrect password. Please try again.';
        break;
      case 'auth/weak-password':
        message = 'Password should be at least 6 characters.';
        break;
      case 'auth/popup-closed-by-user':
        message = 'Authentication popup closed. Please try again.';
        break;
      default:
        message = 'An unexpected error occurred. Please try again.';
        break;
    }
    setError(message);
  };

  // Function to toggle password visibility
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  // Function to toggle between sign-in and sign-up modes
  const toggleSignUp = () => {
    setIsSignUp((prev) => !prev);
    setError('');
  };

  return (
    <div className={styles.container}>
      <Box className={styles.authCard}>
        <Typography variant="h4" className={styles.title}>
          {titleWords.map((word, index) => (
            <span
              key={word}
              className={`${styles.titleWord} ${index === titleIndex ? styles.active : ''}`}
            >
              {word}
            </span>
          ))}
        </Typography>
        <Typography variant="h6" className={styles.subtitle}>
          Unravel the story, one twist at a time!
        </Typography>
        <Typography variant="body2" className={styles.description}>
          Join the adventure and create unexpected narratives with friends.
        </Typography>

        <Button
          variant="contained"
          startIcon={<GoogleIcon />}
          fullWidth
          className={styles.googleButton}
          onClick={handleGoogleSignIn}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} className={styles.buttonProgress} />
          ) : (
            'Continue with Google'
          )}
        </Button>

        <div className={styles.divider}>
          <span>or</span>
        </div>

        <form onSubmit={handleEmailAuth} className={styles.form}>
          <TextField
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className={styles.input}
            autoComplete="email"
          />
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className={styles.input}
            autoComplete={isSignUp ? 'new-password' : 'current-password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                    aria-label="toggle password visibility"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            startIcon={<EmailIcon />}
            className={styles.emailButton}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} className={styles.buttonProgress} />
            ) : isSignUp ? (
              'Sign Up with Email'
            ) : (
              'Sign In with Email'
            )}
          </Button>
        </form>

        <Typography
          variant="body2"
          className={styles.toggleText}
          onClick={toggleSignUp}
          tabIndex={0}
          role="button"
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') toggleSignUp();
          }}
        >
          {isSignUp
            ? 'Already have an account? Sign In'
            : 'New to Plot Twistz? Sign Up'}
        </Typography>

        {/* Display error messages */}
        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError('')}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            severity="error"
            sx={{ width: '100%' }}
            onClose={() => setError('')}
          >
            {error}
          </Alert>
        </Snackbar>
      </Box>
    </div>
  );
};

export default AuthPage;
