import React from 'react';
import styles from './About.module.css';

/**
 * About Component
 * 
 * Provides information about Plot Twistz app and its purpose.
 */
function About() {
  return (
    <div className={styles.aboutContainer}>
      <h1>About Plot Twistz</h1>
      
      <section className={styles.appDescription}>
        <h2>What is Plot Twistz?</h2>
        <p>
          Plot Twistz is an innovative storytelling game that challenges players to create unique and exciting narratives using randomly generated elements. Our app brings together creativity, improvisation, and friendly competition in a fun and engaging way.
        </p>
      </section>

      <section className={styles.nameExplanation}>
        <h2>Why "Plot Twistz"?</h2>
        <p>Our name reflects the creative and dynamic nature of storytelling:</p>
        <ul>
          <li><strong>Plot:</strong> The foundation of every great story.</li>
          <li><strong>Twist:</strong> Unexpected turns that make narratives exciting.</li>
          <li><strong>Z:</strong> A playful twist on 'twists', emphasizing creativity and fun.</li>
        </ul>
        <p>
          "Plot Twistz" encapsulates the essence of our game: crafting surprising, 
          engaging stories through unexpected combinations and creative challenges.
        </p>
      </section>

      <section className={styles.howItWorks}>
        <h2>How It Works</h2>
        <p>
          In Plot Twistz, players are presented with a selection of random cards across three categories:
        </p>
        <ul className={styles.categories}>
          <li>Characters</li>
          <li>Settings</li>
          <li>Plot Elements</li>
        </ul>
        <p>
          Players must choose one card from each category and use these elements to craft a compelling story. The app then uses advanced AI to generate a unique script based on the chosen elements, creating surprising and often hilarious results!
        </p>
      </section>

      <section className={styles.ourMission}>
        <h2>Our Mission</h2>
        <p>
          At Plot Twistz, we believe in the power of storytelling to bring people together, spark creativity, and create unforgettable moments of laughter and imagination. Our mission is to provide a platform that encourages creative thinking, improves narrative skills, and offers endless entertainment for players of all ages.
        </p>
      </section>

      <section className={styles.getStarted}>
        <h2>Ready to Twist Your Plot?</h2>
        <p>
          Jump into the world of Plot Twistz and start creating your own wild stories today! Whether you're playing solo, competing with friends, or using it as a tool for writers and educators, Plot Twistz is here to spark your imagination and take your storytelling to new heights.
        </p>
      </section>
    </div>
  );
}

export default About;