import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './HomeScreen.module.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const GameModeLink = ({ className, title, description, emoji, onClick }) => (
  <div className={`${styles.gameMode} ${className}`} onClick={onClick}>
    <div className={styles.gameModeContent}>
      <h2>{title}</h2>
      <p>{description}</p>
      <span className={styles.emoji} role="img" aria-label={`${title} emoji`}>{emoji}</span>
    </div>
  </div>
);

const HomeScreen = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const handleQuickGameClick = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleQuickGameSelection = (isExplicit) => {
    setOpenDialog(false);
    navigate('/quickgame', { state: { isExplicit } });
  };

  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <h1 className={styles.title}>Plot Twistz</h1>
        <p className={styles.subtitle}>Unravel the story, one twist at a time!</p>
        
        <div className={styles.gameModesGrid}>
          <GameModeLink
            className={styles.quickGame}
            title="Quick Twist"
            description="Race against time in a story sprint"
            emoji="⚡"
            onClick={handleQuickGameClick}
          />
          <GameModeLink
            className={styles.twistDuel}
            title="Twist Duel"
            description="Battle a friend in a narrative showdown"
            emoji="🥊"
            onClick={() => navigate('/headtohead-setup')}
          />
          <GameModeLink
            className={styles.storyCircle}
            title="Story Circle"
            description="Weave tales with multiple players"
            emoji="🎭"
            onClick={() => navigate('/groupplay-setup')}
          />
        </div>

        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Choose Game Mode</DialogTitle>
          <DialogContent>
            Select the content rating for your Quick Game:
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleQuickGameSelection(false)}>Family Friendly</Button>
            <Button onClick={() => handleQuickGameSelection(true)}>R-Rated</Button>
          </DialogActions>
        </Dialog>
      </main>
      
      <div className={styles.decorations}>
        {[...Array(3)].map((_, index) => (
          <div key={index} className={styles.bubble}></div>
        ))}
      </div>
    </div>
  );
};

export default HomeScreen;