import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
} from '@mui/material';
import { auth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { AccountCircle } from '@mui/icons-material';
import styles from './Navbar.module.css';

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openUserMenu = Boolean(anchorEl);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  const handleMenuToggle = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  const handleGameModeClick = (mode, e) => {
    e.preventDefault();
    closeMobileMenu();
    mode === 'quickgame' ? setOpenDialog(true) : navigate(`/${mode}-setup`);
  };

  const handleDialogClose = () => setOpenDialog(false);
  const handleModeSelection = (isExplicit) => {
    setOpenDialog(false);
    navigate('/quickgame', { state: { isExplicit } });
  };

  const handleUserMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleUserMenuClose = () => setAnchorEl(null);
  const handleSignOut = () => {
    auth.signOut();
    handleUserMenuClose();
    navigate('/auth');
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.brand}>
        <NavLink to="/" onClick={closeMobileMenu} className={styles.brandLink}>
          Plot Twistz
        </NavLink>
      </div>

      {/* Hamburger Menu Icon */}
      <div
        className={`${styles.hamburger} ${isMobileMenuOpen ? styles.active : ''}`}
        onClick={handleMenuToggle}
        aria-label="Toggle navigation menu"
        role="button"
        tabIndex={0}
        onKeyDown={(e) => e.key === 'Enter' && handleMenuToggle()}
      >
        <span className={styles.bar}></span>
        <span className={styles.bar}></span>
        <span className={styles.bar}></span>
      </div>

      <ul className={`${styles.navLinks} ${isMobileMenuOpen ? styles.mobileActive : ''}`}>
        <li className={styles.navItem}>
          <NavLink to="/" onClick={closeMobileMenu} className={styles.navLink}>
            Home
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink to="/headtohead-setup" onClick={(e) => handleGameModeClick('headtohead', e)} className={styles.navLink}>
            Head to Head
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink to="/groupplay-setup" onClick={(e) => handleGameModeClick('groupplay', e)} className={styles.navLink}>
            Group Play
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink to="/quickgame" onClick={(e) => handleGameModeClick('quickgame', e)} className={styles.navLink}>
            Quick Game
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink to="/about" onClick={closeMobileMenu} className={styles.navLink}>
            About
          </NavLink>
        </li>
        <li className={styles.navItem}>
          <NavLink to="/contact" onClick={closeMobileMenu} className={styles.navLink}>
            Contact
          </NavLink>
        </li>

        {/* User Area */}
        <li className={styles.navItem}>
          {user ? (
            <>
              <Tooltip title="Account Settings">
                <IconButton
                  onClick={handleUserMenuOpen}
                  size="large"
                  edge="end"
                  color="inherit"
                  aria-label="account of current user"
                  aria-controls="user-menu"
                  aria-haspopup="true"
                  className={styles.userButton}
                >
                  {user.photoURL ? (
                    <Avatar alt={user.displayName || user.email} src={user.photoURL} />
                  ) : (
                    <AccountCircle fontSize="large" />
                  )}
                </IconButton>
              </Tooltip>
              <Menu
                id="user-menu"
                anchorEl={anchorEl}
                open={openUserMenu}
                onClose={handleUserMenuClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <MenuItem disabled>{user.displayName || user.email}</MenuItem>
                <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
              </Menu>
            </>
          ) : (
            <NavLink to="/auth" onClick={closeMobileMenu} className={styles.navLink}>
              Sign In
            </NavLink>
          )}
        </li>
      </ul>

      {/* Quick Game Mode Dialog */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Choose Content Rating</DialogTitle>
        <DialogContent>Select a content rating for your Quick Game:</DialogContent>
        <DialogActions>
          <Button onClick={() => handleModeSelection(false)}>Family Friendly</Button>
          <Button onClick={() => handleModeSelection(true)}>R-Rated</Button>
        </DialogActions>
      </Dialog>
    </nav>
  );
}

export default Navbar;
