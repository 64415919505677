import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, MenuItem, Select, FormControl, Typography, Box, Container } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import styles from './SetupScreen.module.css';

const HeadToHeadSetup = () => {
  const [playerCount, setPlayerCount] = useState(2);
  const [playerNames, setPlayerNames] = useState(['', '']);
  const [scriptRating, setScriptRating] = useState('family-friendly');
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handlePlayerCountChange = (event) => {
    const count = parseInt(event.target.value, 10);
    setPlayerCount(count);
    setPlayerNames(Array(count).fill(''));
  };

  const handleNameChange = (index, event) => {
    const newNames = [...playerNames];
    newNames[index] = event.target.value;
    setPlayerNames(newNames);
  };

  const handleStartGame = () => {
    const newErrors = {};
    playerNames.forEach((name, index) => {
      if (!name.trim()) {
        newErrors[`player${index}`] = 'Name is required';
      }
    });

    if (Object.keys(newErrors).length === 0) {
      navigate('/headtohead-game', { state: { playerNames, scriptRating } });
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <Container className={styles.container}>
      <Box className={styles.setupBox}>
        <Typography variant="h1" className={styles.setupTitle}>
          Head-to-Head Setup
        </Typography>
        
        <FormControl fullWidth className={styles.formControl}>
          <Select
            value={playerCount}
            onChange={handlePlayerCountChange}
            className={styles.input}
            displayEmpty
            IconComponent={() => <ArrowDropDownIcon className={styles.selectIcon} />}
          >
            <MenuItem value="" disabled>Number of Players</MenuItem>
            {[2, 3, 4, 5, 6].map((num) => (
              <MenuItem key={num} value={num}>{num} Players</MenuItem>
            ))}
          </Select>
          <PeopleIcon className={styles.icon} />
        </FormControl>

        {playerNames.map((name, index) => (
          <FormControl key={index} fullWidth className={styles.formControl}>
            <TextField
              value={name}
              onChange={(e) => handleNameChange(index, e)}
              className={styles.input}
              placeholder={`Player ${index + 1} Name`}
              error={!!errors[`player${index}`]}
              helperText={errors[`player${index}`]}
            />
            <PersonIcon className={styles.icon} />
          </FormControl>
        ))}

        <FormControl fullWidth className={styles.formControl}>
          <Select
            value={scriptRating}
            onChange={(e) => setScriptRating(e.target.value)}
            className={styles.input}
            displayEmpty
            IconComponent={() => <ArrowDropDownIcon className={styles.selectIcon} />}
          >
            <MenuItem value="" disabled>Script Rating</MenuItem>
            <MenuItem value="family-friendly">Family Friendly</MenuItem>
            <MenuItem value="r-rated">R-Rated</MenuItem>
          </Select>
          <FamilyRestroomIcon className={styles.icon} />
        </FormControl>

        <Button
          variant="contained"
          onClick={handleStartGame}
          fullWidth
          className={styles.setupButton}
          startIcon={<PlayArrowIcon />}
        >
          Start Game
        </Button>
      </Box>
    </Container>
  );
};

export default HeadToHeadSetup;
