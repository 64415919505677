import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, MenuItem, Select, FormControl, Typography, Box, Container } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import styles from './SetupScreen.module.css'; // Import styles

const GroupPlaySetup = () => {
  const navigate = useNavigate();
  const [playerCount, setPlayerCount] = useState(2);
  const [playerNames, setPlayerNames] = useState({});
  const [scriptRating, setScriptRating] = useState('family-friendly');
  const [errors, setErrors] = useState({});

  // Handle changes to the player count
  const handlePlayerCountChange = (e) => {
    const newCount = e.target.value;
    setPlayerCount(newCount);
    // Automatically add new empty player names if the count increases
    const newPlayerNames = { ...playerNames };
    for (let i = 0; i < newCount; i++) {
      if (!newPlayerNames[i]) {
        newPlayerNames[i] = '';
      }
    }
    setPlayerNames(newPlayerNames);
  };

  // Handle input change for player names
  const handlePlayerNameChange = (index, e) => {
    const newPlayerNames = { ...playerNames, [index]: e.target.value };
    setPlayerNames(newPlayerNames);
  };

  // Modify the handleSubmit function
  const handleSubmit = () => {
    const newErrors = {};
    Object.keys(playerNames).forEach((key) => {
      if (!playerNames[key].trim()) {
        newErrors[key] = 'Name is required';
      }
    });

    if (Object.keys(newErrors).length === 0) {
      const playerNamesArray = Object.values(playerNames);
      console.log('Navigating to group-play-screen with:', { players: playerNamesArray, scriptRating });
      navigate('/group-play-screen', { 
        state: { 
          players: playerNamesArray, 
          scriptRating 
        } 
      });
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <Container className={styles.container}>
      <Box className={styles.setupBox}>
        <Typography variant="h1" className={styles.setupTitle}>
          Group Play Setup
        </Typography>
        
        <FormControl fullWidth className={styles.formControl}>
          <Select
            value={playerCount}
            onChange={handlePlayerCountChange}
            className={styles.input}
            displayEmpty
            IconComponent={() => <ArrowDropDownIcon className={styles.selectIcon} />}
          >
            <MenuItem value="" disabled>Number of Players</MenuItem>
            {[2, 3, 4, 5, 6].map((num) => (
              <MenuItem key={num} value={num}>{num} Players</MenuItem>
            ))}
          </Select>
          <PeopleIcon className={styles.icon} />
        </FormControl>

        {Array.from({ length: playerCount }).map((_, index) => (
          <FormControl key={index} fullWidth className={styles.formControl}>
            <TextField
              value={playerNames[index] || ''}
              onChange={(e) => handlePlayerNameChange(index, e)}
              className={styles.input}
              placeholder={`Player ${index + 1} Name`}
              error={!!errors[index]}
              helperText={errors[index]}
            />
            <PersonIcon className={styles.icon} />
          </FormControl>
        ))}

        <FormControl fullWidth className={styles.formControl}>
          <Select
            value={scriptRating}
            onChange={(e) => setScriptRating(e.target.value)}
            className={styles.input}
            displayEmpty
            IconComponent={() => <ArrowDropDownIcon className={styles.selectIcon} />}
          >
            <MenuItem value="" disabled>Script Rating</MenuItem>
            <MenuItem value="family-friendly">Family Friendly</MenuItem>
            <MenuItem value="r-rated">R-Rated</MenuItem>
          </Select>
          <FamilyRestroomIcon className={styles.icon} />
        </FormControl>

        {/* Submit Button */}
        <Button
          variant="contained"
          onClick={handleSubmit}
          fullWidth
          className={styles.setupButton}
          startIcon={<PlayArrowIcon />}
        >
          Start Game
        </Button>
      </Box>
    </Container>
  );
};

export default GroupPlaySetup;
