import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { generateScript } from '../api';
import {
    CircularProgress,
    Button,
    Box,
    Typography,
    Container,
    Paper,
    Grid,
    Card,
    CardMedia,
    CardContent,
    Fade,
    Zoom,
    Snackbar,
    Alert
} from '@mui/material';
import { ShuffleRounded, MovieCreationRounded } from '@mui/icons-material';
import styles from './HeadToHeadScreen.module.css';  // Make sure to create this CSS module file

// Initial state and reducer function
const initialSelectedCards = {
    characters: [],
    programs: [],
    settings: [],
};

const selectedCardsReducer = (state, action) => {
    switch (action.type) {
        case 'SELECT_CARD':
            return {
                ...state,
                [action.category]: [action.card],
            };
        case 'RESET_SELECTION':
            return initialSelectedCards;
        default:
            return state;
    }
};

// Utility function
const shuffleArray = (array) => {
    return array
        .map((a) => ({ sort: Math.random(), value: a }))
        .sort((a, b) => a.sort - b.sort)
        .map((a) => a.value);
};

const HeadToHeadScreen = () => {
    const { state } = useLocation();
    const { playerNames, scriptRating } = state || {};
    const navigate = useNavigate();

    const [currentPlayer, setCurrentPlayer] = useState(0);
    const [randomCards, setRandomCards] = useState({
        characters: [],
        programs: [],
        settings: [],
    });
    const [selectedCards, dispatchSelectedCards] = useReducer(selectedCardsReducer, initialSelectedCards);
    const [script, setScript] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false);

    const fetchCards = useCallback(async () => {
        try {
            const collections = ['characters', 'programs', 'settings'];
            const allCards = {};
            for (const coll of collections) {
                const querySnapshot = await getDocs(collection(db, coll));
                allCards[coll] = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            }
            return allCards;
        } catch (err) {
            handleError(err);
            return {
                characters: [],
                programs: [],
                settings: [],
            };
        }
    }, []);

    const shuffleCards = useCallback((allCards) => {
        const shuffled = {};
        Object.keys(allCards).forEach(category => {
            if (allCards[category].length >= 3) {
                shuffled[category] = shuffleArray(allCards[category]).slice(0, 3);
            } else {
                handleError(new Error(`Not enough ${category} cards available.`));
                shuffled[category] = [];
            }
        });
        setRandomCards(shuffled);
        return shuffled;
    }, []);

    const toggleSelect = useCallback((card, category) => {
        dispatchSelectedCards({ type: 'SELECT_CARD', category, card });
    }, []);

    const handleGenerateScript = useCallback(async () => {
        setIsGenerating(true);
        setError(null);
        try {
            if (!selectedCards.characters.length || !selectedCards.programs.length || !selectedCards.settings.length) {
                throw new Error('Please select one card from each category');
            }

            const scriptText = await generateScript({
                character: selectedCards.characters[0].name,
                program: selectedCards.programs[0].name,
                setting: selectedCards.settings[0].name,
                isExplicit: scriptRating === 'r-rated'
            });

            if (typeof scriptText !== 'string') {
                throw new Error('Generated script is not a string');
            }

            setScript(scriptText);
        } catch (err) {
            handleError(err);
        }
        setIsGenerating(false);
    }, [selectedCards, scriptRating]);

    const handleNextPlayer = useCallback(() => {
        if (currentPlayer < playerNames.length - 1) {
            dispatchSelectedCards({ type: 'RESET_SELECTION' });
            setScript('');
            setCurrentPlayer(prev => prev + 1);
            shuffleCards(randomCards);
        } else {
            navigate('/');
        }
    }, [currentPlayer, playerNames, navigate, randomCards, shuffleCards]);

    useEffect(() => {
        const initializeGame = async () => {
            const allCards = await fetchCards();
            if (allCards.characters.length || allCards.programs.length || allCards.settings.length) {
                shuffleCards(allCards);
            }
            setLoading(false);
        };

        initializeGame();
    }, [fetchCards, shuffleCards]);

    const handleError = (error) => {
        console.error('Error:', error);
        let message = 'An unexpected error occurred. Please try again.';
        if (error.message.includes('network')) {
            message = 'Network error. Please check your internet connection.';
        } else if (error.message.includes('timeout')) {
            message = 'Request timed out. Please try again.';
        } else if (error.message.includes('cards')) {
            message = error.message;
        }
        setError(message);
    };

    const handleShuffleCards = useCallback(() => {
        shuffleCards(randomCards);
        dispatchSelectedCards({ type: 'RESET_SELECTION' });
    }, [randomCards, shuffleCards]);

    return (
        <Container maxWidth="lg" className={styles.headToHeadContainer}>
            <Paper elevation={3} className={styles.contentWrapper}>
                <Typography variant="h4" gutterBottom align="center">
                    Head to Head
                </Typography>

                <Typography variant="h6" gutterBottom align="center">
                    {playerNames[currentPlayer]}'s turn
                </Typography>

                {loading ? (
                    <Box className={styles.loadingOverlay}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Fade in={!loading}>
                        <Box>
                            <Typography variant="h5" gutterBottom align="center" className={styles.playerPrompt}>
                                Select one card from each category
                            </Typography>

                            <Grid container spacing={3} className={styles.categorySections}>
                                {['characters', 'programs', 'settings'].map((category) => (
                                    <Grid item xs={12} md={4} key={category}>
                                        <Typography variant="h6" gutterBottom className={styles.categoryTitle}>
                                            {category.charAt(0).toUpperCase() + category.slice(1)}
                                        </Typography>
                                        <Grid container spacing={2} className={styles.cardsGrid}>
                                            {randomCards[category].map((card) => (
                                                <Grid item xs={12} sm={6} md={4} key={card.id}>
                                                    <Zoom in={true} style={{ transitionDelay: '250ms' }}>
                                                        <Card
                                                            className={`${styles.card} ${selectedCards[category].some(selectedCard => selectedCard.id === card.id) ? styles.selected : ''}`}
                                                            onClick={() => toggleSelect(card, category)}
                                                            raised={selectedCards[category].some(selectedCard => selectedCard.id === card.id)}
                                                        >
                                                            <CardMedia
                                                                component="img"
                                                                className={styles.cardMedia}
                                                                image={card.image_url}
                                                                alt={card.name}
                                                            />
                                                            <CardContent className={styles.cardContent}>
                                                                <Typography variant="body2" component="p">
                                                                    {card.name}
                                                                </Typography>
                                                            </CardContent>
                                                        </Card>
                                                    </Zoom>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>

                            <Box className={styles.actionButtons}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleGenerateScript}
                                    disabled={
                                        isGenerating ||
                                        !selectedCards.characters.length ||
                                        !selectedCards.programs.length ||
                                        !selectedCards.settings.length
                                    }
                                    className={styles.generateScript}
                                    startIcon={<MovieCreationRounded />}
                                >
                                    {isGenerating ? 'Generating...' : 'Generate Script'}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleNextPlayer}
                                    disabled={isGenerating}
                                    className={styles.nextPlayer}
                                >
                                    {currentPlayer < playerNames.length - 1 ? 'Next Player' : 'Finish'}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="info"
                                    onClick={handleShuffleCards}
                                    className={styles.shuffleCards}
                                    startIcon={<ShuffleRounded />}
                                >
                                    Shuffle Cards
                                </Button>
                            </Box>

                            {script && (
                                <Paper elevation={2} className={styles.scriptContainer}>
                                    <Typography variant="h5" gutterBottom>
                                        Generated Script:
                                    </Typography>
                                    <Box className={styles.scriptText}>
                                        <Typography variant="body1">{script}</Typography>
                                    </Box>
                                </Paper>
                            )}

                            <Snackbar
                                open={!!error}
                                autoHideDuration={6000}
                                onClose={() => setError(null)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            >
                                <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
                                    {error}
                                </Alert>
                            </Snackbar>
                        </Box>
                    </Fade>
                )}
            </Paper>
        </Container>
    );
};

export default HeadToHeadScreen;